import React from "react";
import tw from "twin.macro";

const Container = tw.div`
rounded px-4 py-2 shadow-lg
`;

const Title = tw.h1`
text-center font-bold text-2xl text-white 
`;

const Detail = tw.div`
  py-2 text-white
`;

const TimelineItem = ({ item }) => {
  return (
    <Container style={{ backgroundColor: "#747d8c" }}>
      <Title>{item.company}</Title>
      <Detail>
        {Array.isArray(item.description) ? (
          item.description.map((current) => (
            <p className="block" key={current}>
              {current}
            </p>
          ))
        ) : (
          <p>{item.description}</p>
        )}
      </Detail>
    </Container>
  );
};

export default TimelineItem;
