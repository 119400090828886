import TSP from "../images/tsp.jpg";
import Tetris from "../images/tetris.png";
import Cashier from "../images/cashier.png";
import Bytecode from "../images/bytecode.png";

import Patients from "../images/patients.png";
import Parser from "../images/parser.png";

const projects = [
  {
    key: 1,
    name: "Tetris AI Player",
    description: [
      "Created an autoplayer for the tetris game implemented in Python. Utilised various heuristics to achieve the highest score and survive as long as possible.",
      "Maximised score received by creating conditions to allow the tetris board to accumulate to certain height to increase chance of block combo combinations.",
    ],
    link: "https://github.com/ericma1999/OOP-Coursework",
    image: Tetris,
    tags: ["python"],
  },
  {
    key: 2,
    name: "London Railway TSP",
    description: [
      "Implemented Python class for the London Railway lines providing functions such as finding the shortest distance between any two stops, minimum station stops as well as creating new stations whilst minimising the total distance of the new line (travelling salesman)",
    ],
    link: "https://github.com/ericma1999/algo-coursework",
    image: TSP,
    tags: ["Python", "Jupyter Notebook", "Travelling Salesman", "Algorithms"],
  },
  {
    key: 3,
    name: "HTML Parser",
    description: [
      "Developed HTML parser in both C and Haskell without external libraries. The parser is able to validate tags and attributes as well as the structure of the content",
      "Written report on the comparison of both procedural and functional implementations",
    ],
    link: "https://github.com/ericma1999/PoP-CW3",
    image: Parser,
    tags: ["Haskell", "C", "Parsing"],
  },
  {
    key: 4,
    name: "Cashier Application",
    description: [
      "Created an inventory application in C. Utilised search algorithms to provide user with fast query for items and built calculation methods to get summary.",
    ],
    link: "https://github.com/ericma1999/OOP-Coursework",
    image: Cashier,
    tags: ["C", "Pointers"],
  },
  {
    key: 5,
    name: "Patients Application",
    description: [
      "Patients GUI application implemented in Java with Java Swing",
    ],
    link: "https://github.com/ericma1999/OOP-coursework2",
    image: Patients,
    tags: ["Java", "Swing", "Hospital Management"],
  },
  {
    key: 6,
    name: "Bytecode Optimisation",
    description: [
      "Wrote optimiser to optimiser java bytecode using optimisation techniques such as peephole",
    ],
    link: "https://github.com/ericma1999/compilers-cw-2",
    image: Bytecode,
    tags: ["Java", "Java bytecode", "Compilers Optimisation"],
  },
];

export default projects;
