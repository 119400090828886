const timeline = [
  {
    year: 2021,
    alignment: "right",
    items: [
      {
        id: 3,
        company: "Connected Learning Internship",
        description:
          "- Implement the frontend and backend for Diamant game for incoming first year students at UCL",
      },
    ],
  },
  {
    year: 2020,
    alignment: "left",
    items: [
      {
        id: 2,
        company: "Freelance Developer",
        description: `2 React-Native applications and 3 websites (Django / Flask with React Frontend) Worked solo and in small teams (2-3 people)`,
      },
    ],
  },
  {
    year: 2019,
    alignment: "right",
    items: [
      {
        id: 1,
        company: "Dathena Science",
        description: [
          "- Develop and maintain react components",
          "- Utilise SCRUM framework to deliver requirements",
          "- Write tests using JSDoc and Storybook",
          "- Write requirements documentation on JIRA",
          "- Implement SonarQube rules",
        ],
      },
    ],
  },
];

export default timeline;
