import * as React from "react";
import Memoji from "../images/memojiProfile.jpg";
import Page from "../components/Page";
import SocialButton from "../components/SocialButton";
import { faGithub, faLinkedin } from "@fortawesome/fontawesome-free-brands";
import Timeline from "../components/Timeline";
import TimelineData from "../data/timeline";
import Project from "../components/Project";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import projects from "../data/projects";
import { motion } from "framer-motion";

const SocialSection = () => (
  <div className="px-4 my-4">
    <div className="bg-white rounded-xl md:w-3/4 mx-auto p-4 box-border">
      <h1 className="text-3xl font-bold text-center mb-4">Contact</h1>
      <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row justify-center md:space-x-5">
        <SocialButton
          icon={faLinkedin}
          name="Linkedin"
          url="https://www.linkedin.com/in/passawis/"
        />
        <SocialButton
          icon={faGithub}
          i
          name="Github"
          url="https://github.com/ericma1999"
        />

        <SocialButton
          icon={faEnvelope}
          name="Email"
          url="mailto:official.passawis@gmail.com?Subject=Contact%20From%20Web"
        />
      </div>
    </div>
  </div>
);

const AboutSection = () => (
  <div className="px-4 md:w-3/4 mx-auto" id="#about">
    <div className="p-4 rounded-xl bg-white shadow-xl">
      <h1 className="text-3xl font-bold text-center mb-4">About Me</h1>
      <div className="md:max-w-4xl mx-auto">
        <p>
          Hi, my name is Passawis. I am a second year Computer Science student
          at University College London and have a strong passion for anything
          technology related.
        </p>
        <br />
        <p>
          I'm always eager to learn about new concepts in Finance and Computer
          Science. Passionate about improving my own skills through various
          freelance, internships and contract works. The experiences I have
          gained from these works have molded me into a better computer
          scientist.
        </p>
        <br />
        <p>
          Beyond technology and computing I like to travel. This is my first
          year in London after doing a full-year of online lectures from
          Thailand so I am eager to go out and explore London.
        </p>
      </div>
    </div>
  </div>
);

const ExperienceSection = () => (
  <div className="md:w-3/4 mx-auto" id="experience">
    <h1 className="text-center text-4xl text-white font-bold pt-4">
      Experience
    </h1>
    <Timeline data={TimelineData} />
  </div>
);

const ProjectsSection = () => (
  <div className="md:w-3/4 mx-auto" id="projects">
    <h1 className="text-center text-4xl text-white font-bold pt-4">Projects</h1>
    <div className="flex flex-col justify-center items-center py-4 mt-4 md:space-y-8">
      {projects.map((project) => (
        <div key={project.key} className="w-full" style={{ marginTop: "50px" }}>
          <Project {...project} />
        </div>
      ))}
    </div>
  </div>
);

const Profile = () => (
  <div className="grid grid-cols-1 justify-items-center py-4">
    <div className="h-36 w-36 mt-4">
      <img src={Memoji} alt="my profile" className="rounded-full" />
    </div>
    <div>
      <h1 className="text-4xl text-center font-bold text-white my-4">
        Passawis <br /> Chaiyapattanaporn
      </h1>
      <h2 className="text-xl text-center text-white">
        2nd year Computer Science, UCL
      </h2>
    </div>
  </div>
);

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const IndexPage = ({ location }) => {
  return (
    <Page location={location}>
      <motion.div
        className="mx-auto"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <motion.div variants={item}>
          <Profile />
        </motion.div>
        <motion.div variants={item}>
          <AboutSection />
        </motion.div>
        <motion.div variants={item}>
          <SocialSection />
        </motion.div>
        <motion.div variants={item}>
          <ExperienceSection />
        </motion.div>
        <div className="px-4 py-12">
          <div
            className="md:w-3/4 mx-auto rounded-xl bg-white"
            style={{ height: "2px" }}
          ></div>
        </div>
        <motion.div variants={item}>
          <ProjectsSection />
        </motion.div>
      </motion.div>
    </Page>
  );
};

export default IndexPage;
