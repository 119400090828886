import React from "react";
import tw from "twin.macro";
import TimelineYear from "./TimelineYear";

const Container = tw.div`
flex flex-col mt-6 w-full
`;

const Timeline = ({ data }) => {
  return (
    <Container>
      {data.map((timeline) => {
        return (
          <TimelineYear
            key={timeline.year}
            alignment={timeline.alignment}
            items={timeline.items}
            year={timeline.year}
          />
        );
      })}

      {/* <TimelineYear alignment="right" />
      <TimelineYear alignment="left" /> */}
    </Container>
  );
};

export default Timeline;
