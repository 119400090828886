import React from "react";
import { motion } from "framer-motion";
import tw, { styled } from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled(motion.div)`
  ${tw`rounded-full py-3 border-black border-2`}

  min-width: 130px;
`;

const InfoContainer = tw.div`
flex flex-row justify-center gap-1 
`;

const SocialButton = ({ icon, name, url }) => (
  <a href={url}>
    <Container
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.7 },
      }}
      whileTap={{ scale: 0.9 }}
    >
      <InfoContainer>
        <FontAwesomeIcon icon={icon} size="lg" />
        <p className="self-center">{name}</p>
      </InfoContainer>
    </Container>
  </a>
);
export default SocialButton;
