import * as React from "react";
import tw, { styled } from "twin.macro";

import { faGithub } from "@fortawesome/fontawesome-free-brands";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Project = (props) => {
  const { name, link, image, description, tags } = props;
  return (
    <div className="flex flex-col md:flex-row mx-auto text-center md:justify-between w-full">
      <div className="max-w-sm rounded overflow-hidden mx-auto md:w-1/2">
        <img
          className="w-full rounded-xl shadow-lg"
          src={image}
          style={{ width: "100%", height: "280px" }}
          alt="Sunset in the mountains"
        />
      </div>
      <div className="text-white py-4 md:py-0 md:w-1/2">
        <h1 className="text-center text-xl font-bold pt-4 underline underline-offset-4">
          {name}
        </h1>
        <div
          className="flex flex-col items-center justify-between"
          style={{ height: "80%" }}
        >
          <div>
            {description.map((item, index) => (
              <p key={index} className="mt-4 text-left px-4">
                {" "}
                {item}
              </p>
            ))}
          </div>

          <div className="flex flex-col space-y-3 py-">
            <div>
              <div className="space-x-4">
                {tags.map((tag) => (
                  <p className="text-gray-400 font-bold underline inline">
                    {tag}
                  </p>
                ))}
              </div>
            </div>
            <a href={link}>
              <div className="cursor-pointer">
                <FontAwesomeIcon icon={faGithub} size="lg" color="white" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
