import React from "react";
import tw, { styled } from "twin.macro";
import TimelineItem from "../TimelineItem";

const Container = styled.div`
  ${tw`flex flex-row my-4 md:w-1/2`}
  ${(props) =>
    props.alignment === "right"
      ? tw`md:self-end`
      : tw`md:self-start md:flex-row-reverse`}
`;

const Year = styled.div`
  ${tw`rounded-full h-12 w-12 flex items-center justify-center self-center text-white px-2 mx-2 border-2`}
  ${(props) => (props.alignment === "right" ? tw`md:-ml-6` : tw`md:-mr-6`)}
`;

const TimelineItemContainer = tw.div`
self-center mx-4 w-full lg:w-3/4 space-y-6
`;

const TimelineYear = ({ alignment, items, year }) => {
  return (
    <Container alignment={alignment}>
      <Year alignment={alignment}>{year}</Year>
      <TimelineItemContainer>
        {items.map((item) => {
          return <TimelineItem key={item.id} item={item} />;
        })}
      </TimelineItemContainer>
    </Container>
  );
};

export default TimelineYear;
